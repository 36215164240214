<template>
  <div class="form">
    <el-form :inline="true" :model="search" ref="search" :rules="rules">
      <el-form-item prop="name" label="姓名：">
        <el-input v-model.trim="search.name" clearable placeholder="必填" prefix-icon="el-icon-user">
        </el-input>

      </el-form-item>
      <el-form-item prop="idcard" label="身份证号：">
        <el-input v-model.trim="search.idcard" class="id-card" clearable placeholder="必填">
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="手机号：">
        <el-input v-model="search.mobile" clearable placeholder="非必填">
        </el-input>
      </el-form-item>
      <!-- <el-form-item prop="factor">
        <el-select v-model="search.factor">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button class="query-btn" @click="query">查询</el-button>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
<!--      <br>-->
<!--      <el-link type="warning" :underline="false"><i class="el-icon-warning"></i>部分平台需要手机号审查，查询建议采用三要素查询 </el-link>-->
<!--      <br>-->
<!--      <el-form-item prop="types" label="JT查询：">-->
<!--&lt;!&ndash;        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;        <div style="margin: 15px 0;"></div>&ndash;&gt;-->
<!--        <el-checkbox-group-->
<!--          v-model="search.keys" @change="handleCheckedCitiesChange">-->
<!--          <el-checkbox v-for="city in citys" :label="city.value" :key="city.value">{{city.label}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
import verify from '@/utils/verify';
export default {
  data() {
    var checkIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'));
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'));
      }
      callback();
    };
    var checkmobile = (rule, value, callback) => {
        // console.log(value,this.search.factor)
        if (!value && this.search.factor == 2) {

          return callback(new Error('请输入手机号'));
        }

        var s = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
        if (!s && this.search.factor == 2) {
          callback(new Error('请输入正确格式的手机号'));
        } else {
          callback();
        }

      };
    return {
      citys:[{label:'普通查询',value:0}, {label:'JT综合',value:1}],
      checkAll: false,
      isIndeterminate: true,
      search: { name: '', mobile: '', idcard: null, factor: 1,queryType:0},
      /***
       * 为了搜索填入信息不自动触发校验
       * trigger只有'blur'和'change'两个值, 去除trigger还是会触发自动校验，
       * 暂时未找到禁用自动触发校验的方法
       * 只能将trigger 由 blur 改为 change，避开校验
       */
      rules: {
        name: [{message: '请输入姓名', trigger: 'change' ,required: true}],
        // mobile: [
        // { validator: checkmobile, trigger: 'change' }
        //   // { required: true, message: '请输入手机号', trigger: 'blur' },
        //   // { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'blur' }
        // ],
        idcard: [{required: true, validator: checkIdCard, trigger: 'change' }]
      },
      options:[
        {value: 0, label:'关闭校验'},
        {value: 1, label:'二要素校验'},
        {value: 2, label:'三要素校验'}
      ]
    };
  },
  methods: {
    query() {
      if(this.search.mobile){
        this.search.factor = 2
      }else{
        this.search.factor = 1
      }
      let check = false;
      this.$refs['search'].validate(res => (check = res));
      if (!check) return;
      this.$emit('query', this.search);
    },
    handleCheckAllChange(val) {
      this.search.keys = val ? this.citys.map(city => city.value) : [];
      // console.log(this.search.keys)
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.citys.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.citys.length;
    },
    //清空
    clear(){
      this.$refs.search.fields[0].resetField()
      this.$refs.search.fields[1].resetField()
      this.$refs.search.fields[2].resetField()
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  margin-bottom: 20px;
  .id-card.el-input {
    width: 320px;
  }
  .query-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
</style>
